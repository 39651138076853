// TeamData.js

const teamData = [
    {
      name: 'Bhogi Sumasri',
      rollNumber: '20VV1A1208',
      year: '2023-2024',
    },
    
    {
        name: 'Bontalakoti Saikiran',
        rollNumber: '20VV1A1211',
        year: '2023-2024',
    },
    
      {
        name: 'Shaik Vali Basha',
        rollNumber: '20VV1A1257',
        year: '2023-2024',
      },
     
      {
        name: 'Uppala Kirtana',
        rollNumber: '20VV1A1261',
        year: '2023-2024',
      },

      {
        name: 'Anil Sinthu',
        rollNumber: '22VV5A1273',
        year: '2024-2025'
      }
  ];
  
  export default teamData;